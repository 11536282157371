.side-menu {
  position: absolute;
  right: -10px;
  top: -20px;
  z-index: 999999999999999;
}

#menu {
  position: absolute;
  right: 10%;
  top: 40px;
  width: 40px;
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  transition: 0.5s;
  z-index: 999999999999999999999;
  cursor: pointer;
}

.menu-line1 {
  width: 100%;
  height: 3px;
  background: #fff;
  transition: 0.6s;
}

.menu-line2 {
  width: 80%;
  height: 3px;
  margin-top: 0.7rem;
  background: #fff;
  transition: 0.6s;
}

.menu-line2::before {
  position: absolute;
  content: "";
  width: 66%;
  height: 3px;
  background: white;
  bottom: -7px;
  z-index: 999999;
  right: 0;
}

.rotate2:before {
  background: transparent;
}

.rotate {
  transform: rotateZ(180deg);
}

.rotate1 {
  background: white;
  transform: rotateZ(45deg);
}

.rotate2 {
  background: white;
  width: 100%;
  transform: translate(0, -0.8rem) rotateZ(-45deg);
}

.nav-page1 {
  position: fixed;
  top: 0;
  left: 0;
  background: linear-gradient(180deg, #01041e 72.1%, #01041ed6 95.18%);
  width: 50%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #212121;
  transform: translate(0, -100%);
  z-index: 9999999;
  transition: 0.5s;
}

.nav-page1 span {
  padding: 1rem;
}

.nav-page2 {
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #01041e 72.1%, #01041ed6 95.18%);
  z-index: 1;
  width: 50%;
  height: 100vh;
  transition: 0.8s;
  transform: translate(0, -100%);
}

.transform {
  transform: translate(0, 0);
}

.navigation_menu {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: start;
  justify-content: start;
}

.navigation_menu a {
  color: white;
  font-size: 24px;
  transition: all 0.6s ease;
  font-family: "Montserrat";
  position: relative;
}

.navigation_menu a::before {
  position: absolute;
  content: "";
  width: 100%;
  transition: all 0.6s ease;
  height: 1px;
  background: white;
  bottom: 1px;
}

.navigation_menu a:hover {
  transform: scale(1.1);
  color: #0b296f;
}

.navigation_menu a:hover:before {
  background: #1650c4;
}

.res_menu_Nav {
  display: none;
}

@media (max-width: 768px) {
  #menu {
    position: absolute;
    right: 4rem;
  }

  .navigation_menu a {
    color: white;
    font-size: 20px;
  }
}

@media (max-width: 767px) {
  #menu {
    position: absolute;
    right: 22px;
  }

  .navigation_menu {
    display: none;
  }

  .res_menu_Nav {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .res_menu_Nav a {
    color: white;
    font-size: 25px;
    margin-bottom: 10px;
  }
}
