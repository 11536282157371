/* Imports */
/* Icons */
@import url("https://use.fontawesome.com/releases/v5.14.0/css/all.css");
@import url("https://unpkg.com/boxicons@2.0.9/css/boxicons.min.css");
@import url("https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp");
/* Fonts */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://netdna.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");
/* General Styling */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  text-decoration: none !important;
  list-style-type: none;
  font-family: "Poppins", sans-serif;
}

::selection {
  color: black !important;
  background: white !important ;
}

a {
  display: inline-block;
  color: #000;
  transition: all 300ms ease-in-out;
}

i[class^="bx"] {
  vertical-align: middle;
}

ul,
address {
  margin-bottom: 0;
}

.custom-prev-button {
  position: absolute;
  top: 45%;
  left: 2.5%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  font-size: 34px;
  cursor: pointer;
  color: #fff;
  z-index: 9;
  outline: none;
}

.custom-next-button {
  position: absolute;
  top: 45%;
  right: 2.5%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  font-size: 34px;
  cursor: pointer;
  color: #fff;
  outline: none;
}

.slick-slide slick-active slick-current {
  width: 414px !important;
}

.imgFluid {
  max-width: 100%;
}

.primary-color {
  color: var(--color-primary);
}

.section-content .subHeading {
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5rem;
  margin-bottom: 0.75rem;
}

.section-content .heading {
  font-size: 3.25rem;
  line-height: 1.1;
  letter-spacing: 0.25rem;
  text-transform: uppercase;
  font-weight: bold;
}

.member-logo {
  border-radius: 20px;
  border: 1px solid #2f65c1;
  background: rgba(47, 101, 193, 0.3);
  cursor: pointer;
  padding: 70px 5px 0px 5px;
  transition: all 0.3s ease-in-out;
  margin: 20px 0px;
  width: 200px !important;
}

@media (max-width: 768px) {
  .member-logo {
    width: 100% !important;
  }
}

ul.partners_logos li {
  text-align: center;
  display: flex !important;
  flex-direction: column;
  height: 150px;
  align-items: center;
  justify-content: center;
}

.section-content p {
  color: #666;
  line-height: 2;
}

.section-content span {
  font-weight: 800;
  text-transform: uppercase;
}

.first-banner-btn {
  display: flex;
  gap: 20px;
}

.header-btn .themeBtn {
  margin: 0px 30px 0px 0px;
}

.themeBtn {
  border-radius: 10px;
  border: 1px solid #0c47ec;
  background: #1650c4;
  padding: 15px 35px;
  box-shadow: 0px 10px 30px 0px rgba(12, 71, 236, 0.2);
  color: #fff;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.4px;
  transition: all 0.6s ease;
  transform: translate(0px, 0px);
}

.themeBtn:hover {
  background: transparent;
  color: white;
  transform: translate(0px, -10px);
}

.content-title {
  color: #fff;
  font-family: Montserrat;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  /* 84px */
  letter-spacing: -3.5px;
}

.themeBtn-light {
  border-radius: 10px;
  border: 2px solid #0c47ec;
  box-shadow: 0px 10px 30px 0px rgba(12, 71, 236, 0.2);
  padding: 15px 25px;
  color: #0c47ec;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.4px;
  transform: translate(0px, 0px);
}

.themeBtn-light:hover {
  color: white;
  background: #1650c4;
  transform: translate(0px, -10px);
}

.content-para {
  color: #e6e6e6;

  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
  /* 32.4px */
  letter-spacing: -0.36px;
}

/*********** Header CSS Start *********/

header.header-main {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
  padding: 20px 0px;
}

.header-btn {
  display: flex;
  align-items: center;
  height: 100%;
}

.header-logo img {
  width: 90px;
}

@media (max-width: 600px) {
  .header-logo img {
    width: 60px;
  }
}

.header-btn a svg {
  width: 40px;
}

.header-nav {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 30px;
}

/*********** Header CSS End *********/

/*********** First banner CSS Start *********/

.first-banner-img img {
  width: 100%;
}

.first-banner-img img {
  width: 100%;
}

section.first-banner .container-fluid {
  overflow-x: hidden;
}

.first-banner-txt {
  position: absolute;
  z-index: 99;
  top: 55%;
  width: 760px;
  left: 17.5%;
  transform: translate(-20%, -40%);
}

.first-banner-txt h2 {
  color: #fff;
  font-family: Montserrat;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  /* 84px */
  letter-spacing: -3.5px;
}

.first-banner-txt h2 span {
  color: #0c47ec;
}

.first-banner-slider .slick-slide {
  margin-right: 0;
}

/*********** First banner CSS End *********/

/*********** Second banner CSS Start *********/

.second-banner-img img {
  width: 100%;
}

.second-banner {
  background: linear-gradient(180deg, rgba(1, 4, 30, 0) 72.1%, #01041e 95.18%);
  padding: 0px 0 200px 0;
  background-image: url(../../src/assets/images/home/second_sec_bg.png);
  background-position: left;
  background-size: cover;
}

.second-banner .container-fluid {
  overflow-x: hidden;
}

.second-banner-txt {
  padding: 150px 80px 0px 0px;
}

.second-banner-img img {
  width: 100%;
  mix-blend-mode: color-dodge;
}

/*********** Second banner CSS End *********/

/*********** Members cities countries CSS Start *********/

.social-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
  border-radius: 30px;
  border: 2px solid #2d62bb;
  background: #081d52;
  box-shadow: 0px 20px 60px 0px rgba(47, 101, 193, 0.2);
  position: relative;
}

.social-member {
  display: flex;
  gap: 10px;
  position: relative;
}

.member-txt h3 {
  color: #fff;
  font-family: Montserrat;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  /* 84px */
  letter-spacing: 2px;
  margin: 0;
}

.member-txt p {
  color: #e6e6e6;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
  letter-spacing: -0.52px;
}

.social-member img {
  width: 100px;
  height: 100px;
}

/* .social-member::before {
  position: absolute;
  content: "";
  height: 2px;
  width: 160px;
  left: 220px;
  top: 80px;
  background: #2f65c1;
  transform: rotate(90deg);
}

.social-member:nth-child(2):before {
  left: 140px;
}

.social-member:nth-child(3):before {
  display: none;
} */

.member-txt {
  position: relative;
}

/*********** Members cities countries CSS End *********/

/*********** Third banner CSS Start *********/

.third-banner h2 {
  text-align: center;
}

.third-banner p {
  text-align: center;
  padding: 0px 0px 30px 0px;
}

.bussiness-conten-box {
  padding: 20px 20px;
  border-radius: 20px;
  background: #010634;
  transition: all 0.6s ease;
  border: 1px solid #010634;
  height: 100%;
  margin: 10px 0px;
  transform: translate(0px, 0px);
}

.bussiness-conten-box:hover {
  border: 1px solid #2f65c1;
  background: #081d52;
  box-shadow: 0px 20px 60px 0px rgba(47, 101, 193, 0.3);
  transform: translate(0px, -10px);
}

.third-banner {
  padding: 80px 0px 50px 0px;
}

.bussiness-conten-box p {
  color: #fff;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  /* 33px */
  letter-spacing: -0.66px;
  text-align: left;
  padding: 10px 0px 0px 0px;
  margin: 0;
}

.unique-features {
  padding: 120px 0px 150px 0px;
  background: url(../../src/assets/images/home/uniquebg.png);
  background-position: right;
  background-size: cover;
  background-repeat: no-repeat;
}

.unique-btn {
  display: flex;
  align-items: center;
  gap: 20px;
}

.unique-short-txt {
  border-radius: 20px;
  border: 1px solid #2f65c1;
  background: rgba(45, 98, 187, 0.85);
  box-shadow: 0px 10px 30px 0px rgba(47, 101, 193, 0.15);
  padding: 20px;
  margin: 10px 0px 20px 0px;
  height: 140px;
  transform: translate(0px, 0px);
  transition: all 0.6s ease;
}

.unique-short-txt a {
  color: #fff;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  /* 33px */
  letter-spacing: -0.66px;
}

.unique-txt .row {
  padding: 0px 0px 20px 0px;
}

.unique-short-txt:hover {
  transform: translate(0px, -10px);
}

.pro-advantage {
  padding: 60px 0px 80px 0px;
  background: #010634;
}

.pro-advantage h2 {
  text-align: center;
}

.pro-advantage p {
  text-align: center;
  padding: 0 0 50px 0;
}

.aboutus-bullet-points-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.aboutus-bullet-points {
  border-radius: 20px;
  border: 1px solid #2f65c1;
  background: rgba(47, 101, 193, 0.3);
  height: 250px;
  width: 250px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  padding: 15px;
  display: flex;
  flex-direction: column;
  margin: 10px;
  transition: all 0.3s ease-in-out;
}

.aboutus-bullet-points h4 {
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  padding: 10px 0 0 0;
  text-align: left;
}

.aboutus-bullet-points p {
  color: #fff;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  padding: 0;
  height: 200px;
}

.pro-advantage-box {
  border-radius: 20px;
  border: 1px solid #2f65c1;
  background: rgba(47, 101, 193, 0.3);
  height: 460px;
  min-height: 460px;
  position: relative;
  cursor: pointer;
  padding: 0px 20px 0px 20px;
  transition: all 0.3s ease-in-out;
}

.pro-advantage-box h4 {
  color: #fff;
  font-family: Montserrat;
  font-size: 30px;
  font-weight: 600;
  padding: 10px 0 0 0;
}

.pro-advantage-box p {
  color: #fff;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  padding: 0;
  /* height: 265px; */
  overflow: hidden;
}

.limit-lines {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3; /* Adjust this number to limit the lines */
  overflow: hidden;
}

.pro-advantage-box:hover {
  transform: translate(0px, -8px);
  box-shadow: 0px 20px 60px 0px rgba(47, 101, 193, 0.3);
  background: rgba(47, 101, 193, 0.8);
}

.event-card:hover {
  transform: translate(0px, -8px);
  box-shadow: 0px 20px 60px 0px rgba(47, 101, 193, 0.3);
  background: rgba(47, 101, 193, 0.8);
}

section.news_press_sec {
  background: #01041e;
  width: 100% !important;
  overflow: hidden;
}

.news_box_main {
  border-radius: 20px;
  background: #020735;
  /* padding: 0px 20px 20px 20px; */
  height: 350px;
  overflow: hidden;
}

.new_img_box {
  text-align: center;
  height: 225px;
  width: 100%;
  border-radius: 10px;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  object-fit: contain;
}

.team-images {
  width: 55% !important;
  object-fit: contain;
}

.content_new h3 {
  color: #fff;
  font-family: Montserrat;
  font-size: 22px;
  font-weight: 600;
  padding: 10px 0 10px 0;
}

.content_new p {
  color: #fff;
  font-family: Montserrat;
  font-size: 16px;
  text-align: left;
  font-weight: 400;
  padding: 0;
  margin: 0;
  /* height: 150px; */
  overflow: hidden;
  padding-bottom: "5px";
}

.content_new a {
  color: #2f65c1;
  font-family: Montserrat;
  font-size: 22px;
  /* padding: 20px 0 0 0; */
}

.content_new a:hover {
  transform: translate(0px, -10px);
  color: white;
}

.event-card-a:hover {
  transform: translate(0px, -10px);
  color: white;
}

.events-heading:after {
  content: "";
  display: block;
  background: #6cd3fe none repeat scroll 0 0;
  width: 163px;
  height: 4px;
  margin: 15px 0;
  overflow: hidden;
}

.team-heading:after {
  content: "";
  display: block;
  background: #6cd3fe none repeat scroll 0 0;
  width: 325px;
  height: 4px;
  margin: 15px 0;
  overflow: hidden;
}

.new_img_box img {
  transition: all 0.3s ease-in-out;
}

.news_box_main:hover .new_img_box img {
  transform: scale(1.2);
}

ul.partners_logos {
  width: 100%;
}

ul.partners_logos li img {
  width: 200px;
}

footer {
  background: #010634;
  padding: 50px 0 30px 0;
}

h2.ftr_head {
  color: #fff;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  padding: 0 0 20px 0;
}

.ftr_cont_box1 ul {
  display: flex;
  gap: 10px;
}

.ftr_content_box02 ul li a {
  color: white;
  padding: 2px 0;
  font-size: 16px;
  font-weight: 300;
}

.ftr_contact_ifno a {
  color: white;
  padding: 2px 0;
  font-size: 16px;
  font-weight: 300;
  padding: 0 0 23px 0;
}

.ftr_content_box02 {
  display: flex;
  justify-content: space-between;
  padding: 0;
  gap: 20px;
}

.ftr_content_box01 ul li a {
  color: white;
  font-size: 16px;
  font-weight: 300;
}

.ftr_content_box01 {
  /* padding: 0 0 0 30px; */
}

footer ul li a:hover {
  color: #0062cc;
}

footer ul li a:hover i.bxl-instagram {
  color: #ff1493;
}

.ftr_copyrightes {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 50px 0 30px 0; */
}

.ftr_copyrightes p {
  color: #fff;
  font-family: Montserrat;
  /* font-size: 22px; */
  font-weight: 500;
  padding: 0;
  margin: 0;
}

.ftr_copyrightes ul {
  display: flex;
  gap: 20px;
  padding: 10px 0 0 0;
  align-items: center;
}

.ftr_copyrightes ul li a {
  color: white;
  font-size: 30px;
}

.ftr_contact_ifno ul {
  display: flex;
  gap: 20px;
  padding: 10px 0 0 0;
  align-items: center;
}

.ftr_contact_ifno ul li a {
  color: white;
  font-size: 40px;
}

section.news_press_sec .col-lg-4 {
  padding: 0 0;
}

.news_press_slider button.slick-prev.slick-arrow {
  display: none !important;
}

.news_press_slider button.slick-next.slick-arrow {
  display: none !important;
}

.pro-advantage-box img {
  width: 145px;
}

.one-time .slick-track {
  padding: 30px 0 70px 0;
}

.one-time ul.slick-dots {
  display: flex;
  justify-content: center;
}

.one-time ul.slick-dots li button {
  width: 20px;
  height: 20px;
  background: #193769;
  border-radius: 100%;
}

.one-time ul.slick-dots li.slick-active button {
  background: #4d9cfe;
}

.main_banner {
  width: 100%;
  /* height: 100vh; */
  position: relative;
}

.first-banner-img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.first-banner-img img {
  width: 100%;
  height: 90%;
  object-fit: cover;
}

section.first-banner {
  width: 100%;
  overflow: hidden;
}
.unique-btn a {
  padding: 14px 19px;
}
.unique-txt p {
  font-size: 16px;
}

/*********** Third banner CSS End *********/

@media (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
}
@media (max-width: 1325px) {
  .unique-short-txt a {
    font-size: 16px;
  }
}
@media (max-width: 1024px) {
  .first-banner-txt {
    left: 42%;
  }
  .header-btn {
    padding: 0 50px 0 20px;
  }
  .unique-short-txt a {
    font-size: 12px;
  }
  .apple_store_link {
    display: flex;
    align-items: center;
  }

  .apple_store_link img {
    width: 100px;
  }

  .ftr_cont_box1 ul li img {
    width: 90px;
  }

  ul.partners_logos li img {
    width: 150px;
  }

  .news_box_main {
    padding: 20px 20px 20px 20px;
  }

  .content_new h3 {
    font-size: 24px;
  }

  .content_new p {
    font-size: 12px;
  }

  .pro-advantage-box h4 {
    font-size: 20px;
  }

  .pro-advantage-box p {
    font-size: 13px;
  }

  .unique-short-txt a {
    font-size: 12px;
  }

  .content-para {
    font-size: 12px;
  }

  .unique-btn {
    gap: 10px;
  }

  .themeBtn {
    padding: 15px 15px;
    font-size: 15px;
  }

  .themeBtn-light {
    padding: 15px 20px;
    font-size: 15px;
  }

  .bussiness-conten-box p {
    font-size: 16px;
    padding: 15px 0px 0px 0px;
  }

  .social-member img {
    width: 80px;
    height: 80px;
  }

  .member-txt h3 {
    font-size: 30px;
    line-height: 100%;
  }

  .member-txt p {
    font-size: 16px;
  }

  .social-member::before {
    left: 200px;
    top: 50px;
    width: 120px;
  }

  .social-member:nth-child(2):before {
    left: 130px;
    width: 120px;
  }

  .first-banner-txt h2 {
    font-size: 30px;
  }

  .first-banner-txt {
    bottom: 100px;
  }

  .content-title {
    font-size: 30px;
  }

  .pro-advantage-box {
    height: 460px;
    min-height: 540px;
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1300px;
  }
}

.first-banner-slider ul.slick-dots {
  position: absolute;
  bottom: 60px;
  display: flex;
  justify-content: center;
}

.first-banner-slider ul.slick-dots li button {
  width: 20px;
  height: 20px;
  background: #ffffff8c;
  border-radius: 100%;
}
.first-banner-slider ul.slick-dots li.slick-active button {
  background: white;
}

@media (max-width: 768px) {
  .header-btn .themeBtn {
    margin: 0px 90px 0px 0px;
  }
  .main_banner:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: black;
    top: 0;
    opacity: 0.1;
  }

  .first-banner-slider button.slick-next.slick-arrow {
    position: absolute;
    background: #ffffff94;
    height: 45px;
    width: 45px;
    top: 50%;
    right: 8px;
    border-radius: 0;
  }

  .first-banner-slider button.slick-next.slick-arrow::before {
    content: "\f061";
    font-family: FontAwesome;
    color: black;
  }
  .first-banner-slider button.slick-next.slick-arrow {
    position: absolute;
    background: #ffffff94;
    height: 45px;
    width: 45px;
    top: 50%;
    right: 8px;
    border-radius: 0;
  }

  .first-banner-slider button.slick-next.slick-arrow::before {
    content: "\f061";
    font-family: FontAwesome;
    color: black;
  }

  .first-banner-slider button.slick-next.slick-arrow:hover {
    background: #1650c4;
    color: white;
  }
  .first-banner-slider button.slick-prev.slick-arrow:hover {
    background: #1650c4;
    color: white;
  }

  .first-banner-slider {
    position: relative;
    margin-bottom: 0 !important;
  }

  .first-banner-slider button.slick-prev.slick-arrow {
    position: absolute;
    background: #ffffff94;
    height: 45px;
    width: 45px;
    top: 50%;
    left: 8px;
    border-radius: 0;
  }

  .first-banner-slider button.slick-prev.slick-arrow::before {
    content: "\f060";
    font-family: FontAwesome;
    color: black;
  }

  .first-banner-txt {
    width: 720px;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 55%;
    text-align: center;
  }

  .first-banner-img {
    height: 100vh;
  }

  .first-banner-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .content-para {
    color: #e6e6e6;
    font-family: Montserrat;
    font-size: 12px;
    letter-spacing: normal;
  }

  .first-banner-btn {
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
  }

  .themeBtn {
    padding: 13px 13px;
  }

  .header-btn {
    padding-right: 15px;
  }

  .second-banner-txt {
    padding: 30px 60px 40px 60px;
    text-align: center;
    background: #00000078;
    margin: 0 100px;
  }

  .social-member::before {
    left: 185px;
  }

  .social-member:nth-child(2):before {
    left: 119px;
  }

  .content-title {
    font-size: 20px;
    letter-spacing: normal;
  }

  .bussiness-conten-box {
    height: 90%;
  }

  .unique-btn {
    gap: 10px;
    justify-content: center;
  }

  .unique-txt {
    text-align: center;
  }

  .unique-txt p br {
    display: none;
  }

  ul.partners_logos {
    width: 100%;
  }

  .one-time .slick-track {
    padding: 30px 0 0 0;
    height: fit-content;
  }

  .pro-advantage-box img {
    width: 145px;
    margin: 0 auto;
  }

  .pro-advantage-box p {
    font-size: 11px;
    text-align: center;
    padding: 0 !important;
  }

  .pro-advantage p br {
    display: none;
  }

  .pro-advantage p {
    text-align: center;
    padding: 0 50px 10px 50px;
  }

  .pro-advantage-box {
    height: 390px;
    min-height: 350px;
    text-align: center;
  }

  .content_new {
    text-align: center;
  }

  .content_new p {
    padding: 0;
  }

  .ftr_content_box01 {
    padding: 0 0 0 0px;
  }

  .ftr_content_box02 {
    padding: 0 0 60px 0;
  }

  h2.ftr_head {
    font-size: 18px;
    padding: 0 0 0px 0;
  }

  .ftr_copyrightes {
    padding: 10px 0 0px 0;
    align-items: center;
  }

  .ftr_copyrightes p {
    font-size: 19px;
  }

  .navigation_menu a {
    color: white;
    font-size: 20px;
  }

  .third-banner p br {
    display: none;
  }

  .third-banner p {
    text-align: center;
    padding: 0px 80px 30px 80px;
  }

  .bussiness-conten-box p {
    padding: 10px 0 0 0;
    text-align: left;
  }

  .unique-short-txt {
    height: 90px;
    display: flex;
    align-items: center;
  }

  .one-time .col-lg-4 {
    padding: 0;
    margin-right: 29px;
  }

  .pro-advantage-box h4 {
    font-size: 23px;
  }

  .one-time ul.slick-dots {
    display: flex;
    justify-content: center;
    bottom: -60px;
  }
}

@media (max-width: 767px) {
  .side-menu {
    right: 0 !important;
  }
  .social-member {
    width: 120px;
  }
  .header-btn .themeBtn {
    margin: 0px 62px 0px 0px;
  }
  .first-banner-txt {
    width: 331px;
    z-index: 99;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 48%;
    text-align: center;
  }
  .first-banner-txt h2 br {
    display: none;
  }

  .first-banner-btn a {
    padding: 8px 15px;
  }
  .social-detail {
    flex-direction: column;
    gap: 20px;
  }

  .social-member::before {
    display: none;
  }

  ul.partners_logos {
    width: 100%;
  }

  .second-banner-txt {
    padding: 30px 10px 20px 10px;
    margin: 0;
  }

  .first-banner-txt h2 {
    font-size: 14px;
    letter-spacing: normal;
  }

  .first-banner-txt p br {
    display: none;
  }

  .first-banner-txt p {
    font-size: 10px;
    padding: 0px 20px;
  }

  #menu {
    position: absolute;
    right: 2rem;
  }

  .nav-page2.transform {
    display: none;
  }

  .nav-page1 {
    width: 100% !important;
  }

  .first-banner-img img {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
  }

  .social-member img {
    width: 49px;
    height: 50px;
  }

  .social-member img {
    width: 49px;
    height: 50px;
  }

  .third-banner p {
    text-align: center;
    padding: 0 10px;
    font-size: 13px;
  }

  .member-txt p {
    font-size: 14px;
  }

  .member-txt h3 {
    font-size: 20px;
    letter-spacing: normal;
  }

  .second-banner {
    padding: 40px 0 80px 0;
    background-position: 0 -100px;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .one-time .col-lg-4 {
    padding: 0;
    margin-right: 10px;
    /* margin-left: 10px; */
  }

  .one-time .slick-track {
    padding: 30px 0 0 0;
  }

  .content_new h3 {
    font-size: 20px;
  }

  .content_new p {
    padding: 0;
    font-size: 11px;
  }

  .ftr_content_box02 {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    padding: 0 0 30px 0;
  }

  h2.ftr_head {
    font-size: 16px;
    padding: 10px 0 10px 0;
    text-align: center;
  }

  .ftr_cont_box1 ul {
    display: flex;
    gap: 10px;
    justify-content: center;
    padding: 10px 0 20px 0;
  }

  .ftr_content_box02 ul li {
    text-align: center;
  }

  .ftr_content_box01 ul li {
    text-align: center;
    padding: 0px 0px 7px 0px;
  }

  .ftr_contact_ifno {
    text-align: center;
  }

  .ftr_contact_ifno a {
    color: white;
    padding: 2px 0;
    font-size: 16px;
    font-weight: 300;
    display: block;
    padding: 0 0 3px 0;
  }

  .apple_store_link {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0 10px 0;
  }

  .ftr_copyrightes p {
    font-size: 11px;
  }

  .ftr_copyrightes ul li a {
    color: white;
    font-size: 15px;
  }
  .ftr_contact_ifno ul li a {
    color: white;
    font-size: 25px;
  }

  .first-banner-img {
    height: 55vh;
  }

  .themeBtn-light {
    padding: 15px 20px;
    font-size: 10px;
  }

  .themeBtn {
    padding: 15px 15px;
    font-size: 12px;
  }

  .unique-short-txt a {
    font-size: 12px;
  }

  .bussiness-conten-box {
    height: 90%;
    text-align: center;
  }

  .unique-features {
    padding: 0px 0px 40px 0px;
  }

  .news_press_slider .col-lg-3 {
    margin: 0;
  }
  ul.partners_logos li img {
    width: 170px;
  }
}
@media (max-width: 375px) {
  .first-banner-txt {
    width: 270px;
  }
  .pro-advantage-box {
    padding: 0px 20px 0px 20px;
  }
}
